import { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';
import { FieldApi, useForm } from '@tanstack/react-form';
import { valibotValidator } from '@tanstack/valibot-form-adapter';
import { minLength, string, email, custom } from 'valibot';
import axios from 'axios';
import attribute from '../utils/attribute';
import { PageData } from '@/gem.types';

function getAttribution() {
  return new URLSearchParams(window.location.search).get('attribution');
}

interface ContactCaptureFormProps {
  theme: PageData['theme'];
  gemId: string;
  element: any;
  referrer: string;
  endpoint: string;
}

const ContactCaptureForm = ({
  theme,
  gemId,
  element,
  referrer,
  endpoint
}: ContactCaptureFormProps) => {
  const [state, setState] = useState<'idle' | 'submitting' | 'redirecting' | 'thank-you'>('idle');

  const attributionValue = getAttribution();

  const form = useForm({
    onSubmit: async ({ value }) => {
      setState('submitting');

      await axios.post(`${endpoint}/public/gems/contactCapture`, {
        userId: referrer,
        gemId,
        ...value
      });

      if (element.redirectType === 'thank-you') {
        setState('thank-you');
      }

      if (element.redirectType === 'gem') {
        setState('redirecting');
        if (element.redirectGem) {
          switch (element.redirectGem.type) {
            case 'video':
            case 'image':
            case 'embed':
            case 'interactive':
            case 'scheduler':
            case 'page':
            case 'text':
              window.location.href = `/${element.redirectGem.shortId}${
                attributionValue ? `?attribution=${attributionValue}` : ''
              }`;
              break;
            case 'link':
            case 'product':
              window.location.href =
                attribute(element.redirectGem.url, attributionValue || '') || '';
              break;
          }
        }
      }

      if (element.redirectType === 'url') {
        window.location.href = element.redirectUrl || '';
      }
    },
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    },
    validatorAdapter: valibotValidator
  });

  if (!element.fields) {
    return <></>;
  }

  function renderFormFields(fields: { name: boolean; email: boolean; phone: boolean }) {
    return (
      <>
        {fields.name && (
          <>
            <form.Field
              name="firstName"
              validators={{
                onChange: string([minLength(1, 'First name is required')])
              }}
            >
              {(field) => (
                <div className="mb-4">
                  <input
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    placeholder="First Name"
                    className={`form-input w-full px-4 py-3 rounded-lg border focus:outline-none ${
                      field.state.meta.isTouched && field.state.meta.touchedErrors.length
                        ? 'border-red-500'
                        : 'border-[var(--page-field-border-color)]'
                    } focus:border-[var(--page-primary-color)] text-dark bg-white`}
                  />
                  <FieldInfo field={field} />
                </div>
              )}
            </form.Field>
            <form.Field
              name="lastName"
              validators={{
                onChange: string([minLength(1, 'Last name is required')])
              }}
            >
              {(field) => (
                <div className="mb-4">
                  <input
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    placeholder="Last Name"
                    className={`form-input w-full px-4 py-3 rounded-lg border focus:outline-none ${
                      field.state.meta.isTouched && field.state.meta.touchedErrors.length
                        ? 'border-red-500'
                        : 'border-[var(--page-field-border-color)]'
                    } focus:border-[var(--page-primary-color)] text-dark bg-white`}
                  />
                  <FieldInfo field={field} />
                </div>
              )}
            </form.Field>
          </>
        )}
        {fields.email && (
          <form.Field
            name="email"
            validators={{
              onChange: string([email('Invalid email')])
            }}
          >
            {(field) => (
              <div className="mb-4">
                <input
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                  placeholder="Email"
                  className={`form-input w-full px-4 py-3 rounded-lg border focus:outline-none ${
                    field.state.meta.isTouched && field.state.meta.touchedErrors.length
                      ? 'border-red-500'
                      : 'border-[var(--page-field-border-color)]'
                  } focus:border-[var(--page-primary-color)] text-dark bg-white`}
                />
                <FieldInfo field={field} />
              </div>
            )}
          </form.Field>
        )}
        {fields.phone && (
          <form.Field
            name="phone"
            validators={{
              onChange: string([minLength(1, 'Phone number is required'), phoneNumber])
            }}
          >
            {(field) => (
              <div className="mb-4">
                <PhoneInput
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(value) => {
                    field.handleChange(value as string);
                  }}
                  useNationalFormatForDefaultCountryValue={false}
                  placeholder="Phone"
                  className={`form-input w-full px-4 py-3 rounded-lg border focus:outline-none ${
                    field.state.meta.isTouched && field.state.meta.touchedErrors.length
                      ? 'border-red-500'
                      : 'border-[var(--page-field-border-color)]'
                  } focus:border-[var(--page-primary-color)] text-dark bg-white`}
                />
                <FieldInfo field={field} />
              </div>
            )}
          </form.Field>
        )}
      </>
    );
  }

  if (state === 'thank-you') {
    return (
      <div
        className="w-full px-8 py-6 rounded-lg mb-10"
        style={{ border: `2px solid ${theme.borderColor}`, backgroundColor: theme.containerColor }}
      >
        <h2
          className="text-2xl font-bold text-center w-full leading-tight"
          style={{ color: theme.textColor }}
        >
          {element.redirectThankYouMessage}
        </h2>
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
      className="w-full px-8 py-6 rounded-lg mb-10"
      style={{
        border: `1px solid ${theme.fieldBorderColor}`,
        backgroundColor: theme.containerColor
      }}
    >
      {element.fields && renderFormFields(element.fields)}
      <button
        type="submit"
        className="font-semibold text-lg py-3 px-6 rounded-lg w-full flex justify-center text-center transition duration-300 ease-in-out transform hover:scale-105"
        style={{ backgroundColor: theme.primaryColor, color: theme.buttonTextColor }}
      >
        {state !== 'idle' ? <>Submitting...</> : 'Submit'}
      </button>
    </form>
  );
};

function FieldInfo({ field }: { field: FieldApi<any, any, any, any> }) {
  return (
    <>
      {field.state.meta.touchedErrors ? (
        <span className="text-sm" style={{ color: '#dc0000' }}>
          {field.state.meta.touchedErrors}
        </span>
      ) : null}
      {field.state.meta.isValidating ? 'Validating...' : null}
    </>
  );
}

const phoneNumber = custom((value: string) => {
  if (!value) return true;

  try {
    return isValidPhoneNumber(value);
  } catch {
    return false;
  }
}, 'Invalid phone number');

export default ContactCaptureForm;
